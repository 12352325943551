import React, {
  Fragment
  , PureComponent
  , useCallback
  , memo
  , useMemo
  , useEffect
	, useState
} from 'react';
import classNames from 'classnames';
import { renderFileActionBtns as renderActionButtons } from '../admin';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
// import { renderEditButton } from '../admin';
import { I, webRoot } from '../../../common/v5/config';
import {
  ListHeaderWrapper,
  ListContentWrapper,
  TableHeader,
  editorTitleByView,
  EditorBox,
  EditorFooter,
  EditorHeader,
  SaveButton,
  CopyButton,
  AdminListAndEditLayout,
  withEditWrap,
} from '../../../reactcomponents/Admin';
import {
  ADMIN_TITLE,
  M_CONTACT_CARDS,
  FORM_EMPTY,
	FORM_EDIT,
	FORM_CREATEGROUP,
	FORM_MANAGEGROUP,
	FORM_LISTGROUP,
  FORM_UPLOAD
} from '../../../common/v5/constants';
import ContactCardsEditForm, { ContactCardsEdit as EditForm } from './contactCardsEdit';
import { TableIconicButton } from '../../../reactcomponents/Form';
import FileUploader from '../../../components/FileUploader';
import Helper from '../../../reactcomponents/Helper'
import { customPageTotal } from '../../../reactcomponents/Table';

const uploadHelperTxt = <div>
<div>{I("The file should contain one row for each customer card, The information should be separated by a comma, for example:")}</div>
<br/>
<div>{I("Name, email address, phone number, facebook id, line id, vk id, city, postcode, custom_label")}</div>
<br/>
<div>{"Tim Smith, tim@gmail.com, +4412345667, 11111111111@facebook.com, centionline-c1485928005, centionvk-cc-121256154, Henningsvær, 8312, 237489"}</div>
</div>

export const BootstrapSearchBar = ({searchProps, className, placeholder, icon}) => {
  let input;
  const handleSearch = () => {
    searchProps.onSearch(input.value);
  };
  return (
    <div className={className}>
      <i className={icon}></i>
      <input
        placeholder={placeholder}
        ref={ n => input = n }
        type="text"
        onChange={handleSearch}
      />
      {/* <button className="btn btn-warning" onClick={ handleClick }>Click to Search!!</button> */}
    </div>
  );
};

const ContactCardsUploadForm = ({onFetchAdminList}) => {

  let importInterval = null;

  const [status, setStatus] = useState(I("Add Contact Card entries from excel or csv file."))

  const handleFileUpload = (rs) => {
    if(rs.status) {
			setTimeout(function(){
				contactImportStatus();
			}, 1000);
		} else {
			setStatus(I("File uploading failed! Please contact support."))
		}
  }
  const contactImportStatus = () => {
    $.get(webRoot + "admin/contactcards/contactImportStatus")
		.done(function(rs){
			if(rs.list.length > 0){
				setStatus(I("Please wait while the system completes the upload process. Imported data will be available in the list shortly."));
				clearInterval(importInterval);
				importInterval = setInterval(function(){
					contactImportStatus();
				}, 1000);
			}else{
				clearInterval(importInterval);
				// window.location.reload();
        onFetchAdminList();
			}
		})
  }
  return (
    <div id="contactCardUpload">
      <div className='upload-input-wrap'>
        <h3 className='title mb-2'>{I("Upload file")}</h3>
        <div className='pull-right upload'>
          <FileUploader
            className="btn-file-upload-wrap"
            uploadTo="admin/contactcards/upload"
            multipleFile={false}
            // onProgress={this.handleUploadProgress}
            onFileupload={handleFileUpload}
          />
          <Helper>{uploadHelperTxt}</Helper>
				</div>
      </div>
      <span className='label'>{status}</span>
    </div>
  )
}

// const EditForm = withEditWrap(ContactCardsEditForm);
const UploadForm = withEditWrap(ContactCardsUploadForm);

const ContactCards = ({
	view
	, onLoad
	, showInline
	, list
  , notes
	, onLoadToEdit
	, onChangeAdminInput
  , onSaveAdminParams
  , onAddAccount
	, onDeleteFromList
	, onCloseForm
	, activeId
	, input
  , formType
  , dataSrc
  , companyList
  , defaultService
  , onDeleteAccount
  , onDeleteAvatar
  , onAddNote
  , onFileupload
  , onAttachmentDelete
  , noteAttachment
  , onClearAttachments
  , onLoadToUpload
  , onFetchAdminList
  , onDeleteNote
  , onShowOtherContact
  , onMergeContactCards
  , onToggleMergePopup
  , showMergeContactPopup
  , highlight
}) => {
  useEffect(() => {
    if (onLoad) {
      onLoad(M_CONTACT_CARDS, false);
    }
  }, []);

  const handleDelete = (id) => {
		let confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) { // TODO: avoid using primitive confirm box
			onDeleteFromList(id);
		}
	}
  const handleTextInputChange = (e) => {
		let field = e.target.name, value = e.target.value;
		onChangeAdminInput(field, value);
	}

  const actionDatas = {
    // onDownload: onDownloadFromList,
    onDelete: handleDelete,
    onEdit: onLoadToEdit
    // selectedAreas: [1, 23, 4]
  }
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true
    },
    {
      dataField: 'searchTerm',
      text: 'Keywords',
      sort: true,
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      isDummyField: true,
      dataField: 'action',
      text: 'Action',
      headerClasses: 'center',
      formatter: (cell, row) => {
        return renderActionButtons(cell, row, actionDatas);
      },
    }
  ];

  const defaultSorted = [{
    dataField: 'createtime',
    order: 'desc'
  }];

  const isNew = activeId <= 0;
  const options = {
    paginationSize: 3,
    pageStartIndex: 1,
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    showTotal: true,
    paginationTotalRenderer: customPageTotal,
    disablePageTitle: true,
    sizePerPageList: [10] //todo: give user the option to choose instead of fixed value
  };

  const isUpload = (formType === FORM_UPLOAD)

  let listSection = <Fragment>
                      <ListHeaderWrapper>
                        <TableHeader
                          title={ADMIN_TITLE[view]}
                          type='button'
                          iconClass='icon-add'
                          onClickAdd={e => onLoadToEdit(0)}
                          hide={false}
                          hideAddIcon={false}
                          showInline={showInline}
                          // onToggleAdminFormType={toggleFormType}
                        >
                          <TableIconicButton
                            type='button'
                            className={classNames("btn tab-btn bg-transparent btn-round",{"active": isUpload})}
                            iconClass='icon-upload'
                            title={I('Upload contact')}
                            // onClick={toggleFormType}
                            onClick={onLoadToUpload}
                            value={FORM_CREATEGROUP}
                            // disabled={isCreate}
                          />
                        </TableHeader>
                      </ListHeaderWrapper>
                      <ListContentWrapper className={"v5-table-style"}>
                      <ToolkitProvider
                        keyField="id"
                        data={ list }
                        columns={ columns }
                        search
                      >
                        {
                          props => (
                            <div>
                              <BootstrapSearchBar
                                searchProps={props.searchProps}
                                className="search-field-text"
                                placeholder={I("Search by name, email or phone number")}
                                icon="icon-search"
                              />
                              <br/>
                              <BootstrapTable
                                keyField="id"
                                data={list}
                                noDataIndication={I("No data to display")}
                                columns={columns}
                                bordered={false}
                                condensed={false}
                                defaultSorted={defaultSorted}
                                pagination={ paginationFactory(options) }
                                hover
                                {...props.baseProps}
                              />
                            </div>
                          )
                        }
                      </ToolkitProvider>
                      </ListContentWrapper>
                    </Fragment>;

  let editSection = <Fragment>
                      <EditorHeader
                        hidden={!showInline}
                        isNew={isNew}
                        onClickClose={onCloseForm}
                        title={editorTitleByView(isNew, view)}
                      />
                      {formType === FORM_UPLOAD &&
                      <UploadForm hidden={!showInline} onFetchAdminList={onFetchAdminList}/>}
                      {formType === FORM_EDIT &&
                      <EditForm
                        activeId={activeId}
                        input={input}
                        dataSrc={dataSrc}
                        companyList={companyList}
                        view={view}
                        onTextInputChange={handleTextInputChange}
                        onChangeAdminInput={onChangeAdminInput}
                        onSave={onSaveAdminParams}
                        onAddAccount={onAddAccount}
                        onCancel={onCloseForm}
                        onDelete={handleDelete}
                        isNew={isNew}
                        hidden={!showInline}
                        defaultService={defaultService}
                        onDeleteAccount={onDeleteAccount}
                        onLoadToEdit={onLoadToEdit}
                        notes={notes}
                        onAddNote={onAddNote}
                        onFileupload={onFileupload}
                        onAttachmentDelete={onAttachmentDelete}
                        noteAttachment={noteAttachment}
                        onClearAttachments={onClearAttachments}
                        onDeleteAvatar={onDeleteAvatar}
                        onDeleteNote={onDeleteNote}
                        onShowOtherContact={onShowOtherContact}
                        contactCardList={list}
                        onMergeContactCards={onMergeContactCards}
                        showMergeContactPopup={showMergeContactPopup}
                        onToggleMergePopup={onToggleMergePopup}
                        highlight={highlight}
                      />
                      }
                    </Fragment>;

  return (
    <AdminListAndEditLayout
      idClass={"AdminContactCard"}
      className={"contact-card-page"}
      listSection={listSection}
      editSection={editSection}
    // extraComponent={extraComponent}
    />
  );
}

export default ContactCards;
