import { connect } from 'react-redux';
import {
	changeAdminView
	, toggleAdminEdit
	, setAdminInput
	, resetAdminInput
	, adminActionStatus
} from '../../../redux/actions/admin';
//
import {
	getAdminListSelector
	, listColumnSelector
} from '../../../redux/selectors/admin';
import {
	fetchAdminList,
	sstAsyncs
} from '../../../redux/actions/async/admin';
import { orgAreaMemo } from '../../../redux/selectors/server'
import {
	togglePopWaiting
	, clearPopWaiting
	, popErrorOnly
} from '../../../redux/actions/hmf'
import { onlyActiveAreasSelector } from '../../../redux/selectors/workflow'
import { pleaseWaitString } from '../../../common/v5/helpers';
import { TXT_DELETING } from '../../../common/v5/chatbotConstants'

const mapState = (store, props) => {
    const server = store.server
		, app = store.app
		, admin = app.admin
		, ui = admin.ui
		, adminSt = admin.admin
		, wf = app.workflow
		;
	return {
		view: ui.view,
		list: getAdminListSelector(store),
		langSrc: adminSt.langSrc,
		order: listColumnSelector(store),
		show: adminSt.show,
		showInline: adminSt.showInline,
		showList: adminSt.showList,
		activeId: adminSt.activeId,
		areaList: onlyActiveAreasSelector(store),
		input: adminSt.input,
		orgArea: orgAreaMemo(store),
		filter: adminSt.filter
	};
};

const mapDispatch = (dispatch, props) => {
	return {
		onLoad: (view, freshLoad) => {
			dispatch(changeAdminView(view));
			dispatch(fetchAdminList(view));
		},
		onLoadToEdit: (id, view, popout, list) => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, true, false, true));
		},
		onChangeAdminInput: (field, value, key) => {
			dispatch(setAdminInput(field, value, key));
		},
		onSaveAdmin: (view, params) => {
			dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(sstAsyncs[view].save(params))
			.then((rs) => {
				dispatch(fetchAdminList(view));
				dispatch(resetAdminInput());
				dispatch(toggleAdminEdit(0, false, false, true));
				dispatch(clearPopWaiting());
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("error saving :", err)
				dispatch(clearPopWaiting());
				dispatch(adminActionStatus({ status: 3, msg: err }));
				return dispatch(popErrorOnly(err));
			});
		},
		onDeleteFromList: (id, view, popout, list) => {
			dispatch(togglePopWaiting(pleaseWaitString(TXT_DELETING)));
			dispatch(sstAsyncs[view].remove(id))
			.then((rs) => {
				dispatch(fetchAdminList(view));
				//close form
				dispatch(toggleAdminEdit(0, false, false, true));
				dispatch(clearPopWaiting());
			})
			.catch(err => {
				console.log("error deleting :", err)
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onCloseForm: (id, view, popout, list) => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, false, false, true));
		}
	}
};

const withAccessibility = connect(mapState ,mapDispatch)

export default withAccessibility;