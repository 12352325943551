import { connect } from 'react-redux';
import ContactCards from './contactCards';
import {
	ADMIN_VIEW_MAP
	, emptyArray
	, TXT_DOWNLOADING_DATA
	, TXT_DOWNLOAD_FAIL
	, M_CONTACT_CARDS
	, FORM_EMPTY
	, FORM_CREATEGROUP
	, FORM_EDIT
	, FORM_UPLOAD
} from '../../../common/v5/constants';
import {
	insertEditorContent
	, resetInsertionEditorContent
	, toggleAdminSidebar
	, changeAdminView
	, toggleAdminEdit
	, toggleAdminFormType
	, setAdminInput
	, resetAdminInput
	, showMergeContactCardPopup
	, setAdminHighlight
} from '../../../redux/actions/admin';
//
import {
	adminAdmin
	, currentSortFieldMemo
	, currentSortOrder
	, getAdminListSelector
	, getAdminChannelSelector
	, listColumnSelector
	, getAdminListDefSelector
	, selectEmailChannel
} from '../../../redux/selectors/admin';
import { companyListSelector, customerNoteAttachments, contactCardOtherContactSelector } from '../../../redux/selectors/errand';
import {
	openEditForm,
	fetchAdminList,
	setAdminData,
	removeAdminData,
	accountsAsyncs,
	sstAsyncs
} from '../../../redux/actions/async/admin';
import { uploadClientNoteAttachment,
	showOtherContactErrand,
	reloadBasicErrand} from '../../../redux/actions/async/errand';
import { orgAreaMemo } from '../../../redux/selectors/server'
import {
	togglePopWaiting
	, clearPopWaiting
	, popErrorOnly
	, toggleToastAlert
} from '../../../redux/actions/hmf'
import { onlyActiveAreasSelector } from '../../../redux/selectors/workflow'
import { pleaseWaitString } from '../../../common/v5/helpers';
import { TXT_FETCHING_DATA, TXT_DELETING } from '../../../common/v5/chatbotConstants'
import { confirmDeleteNoteAttachment } from '../../v5/errandCtnrs'
import { setContactCard } from '../../../redux/actions/errand';
import { TOAST_TYPE, TOAST_POSITION } from '../../../reactcomponents/Modal';

const mapContactCardsState = (store, props) => {
    const server = store.server
		, app = store.app
		, admin = app.admin
		, ui = admin.ui
		, adminSt = admin.admin
		, wf = app.workflow
		;
	return {
		view: ui.view,
		list: getAdminListSelector(store),
		langSrc: adminSt.langSrc,
		order: listColumnSelector(store),
		show: adminSt.show,
		showInline: adminSt.showInline,
		showList: adminSt.showList,
		activeId: adminSt.activeId,
		areaList: onlyActiveAreasSelector(store),
		input: adminSt.input,
		orgArea: orgAreaMemo(store),
		filter: adminSt.filter,
		formType: adminSt.formType,
		showMergeContactPopup: adminSt.showMergeContactPopup,
		highlight: adminSt.highlight,
		dataSrc: adminSt.dataSrc,
		notes: adminSt.contactNotesList,
		noteAttachment: customerNoteAttachments(store),
		otherContacts: contactCardOtherContactSelector(store, props),
		companyList: companyListSelector(store, props),
		defaultService: selectEmailChannel(store)
	};
};

const mapContactCardsAction = (dispatch, props) => {
	return {
		onLoad: (view, freshLoad) => {
			dispatch(changeAdminView(view));
			dispatch(fetchAdminList(view));
		},
		onLoadToEdit: (id, view, popout, list) => {
			dispatch(toggleAdminFormType(FORM_EMPTY));
			//todo: maybe can refactor resets
			dispatch(resetAdminInput());
			dispatch(setContactCard("noteAttachment", []))
			// dispatch(toggleAdminEdit(0, false, false, true));
			dispatch(toggleAdminEdit(id, false, false, true));
			dispatch(togglePopWaiting(pleaseWaitString(TXT_FETCHING_DATA)));
			dispatch(sstAsyncs[M_CONTACT_CARDS].notes({groupid:id}))
			dispatch(sstAsyncs[M_CONTACT_CARDS].one(id))
				.then((rs) => {
					dispatch(clearPopWaiting());
					// dispatch(toggleAdminEdit(id, true, false, true));
					dispatch(toggleAdminFormType(FORM_EDIT));
				})
				.catch(err => {
					console.log("error getting records:", err);
					dispatch(clearPopWaiting());
					return dispatch(popErrorOnly(err));
				})
		},
		onLoadToUpload: (id, view, popout, list) => {
			dispatch(toggleAdminFormType(FORM_UPLOAD));
			//todo: maybe can refactor resets
			dispatch(resetAdminInput());
			dispatch(setContactCard("noteAttachment", []))
			// dispatch(toggleAdminEdit(0, false, false, true));
			dispatch(toggleAdminEdit(0, true, false, true));
		},
		onLoadNotes: (id, view, popout, list) => {
			dispatch(sstAsyncs[M_CONTACT_CARDS].notes({groupid:id}))
				.then((rs) => {
					dispatch(clearPopWaiting());
				})
				.catch(err => {
					console.log("error getting notes:", err);
					dispatch(clearPopWaiting());
					return dispatch(popErrorOnly(err));
				})
		},
		onToggleAdminFormType: (formType) => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminFormType(formType));
		},
		onChangeAdminInput: (field, value, key) => {
			dispatch(setAdminInput(field, value, key));
		},
		onSaveAdmin: (view, createNew, id, input) => {
			dispatch(setAdminData(view, createNew, id, input));
		},
		onSaveAdminParams: (view, params, id) => {
			//this passes data arguments received from states
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(sstAsyncs[M_CONTACT_CARDS].save(params))
			.then((rs) => {
				dispatch(fetchAdminList(view));
				if(id){
					dispatch(sstAsyncs[M_CONTACT_CARDS].one(id))
					.then((rs) => {
						dispatch(clearPopWaiting());
					})
				}else {
					dispatch(toggleAdminEdit(0,false));
					dispatch(clearPopWaiting());
				}
			})
			.catch(err => {
				console.log("error saving contact :", err)
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onMergeContactCards: (view, params, id, sourceName, targetName) => {
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(sstAsyncs[M_CONTACT_CARDS].merge(params))
			.then((rs) => {
				dispatch(fetchAdminList(view));
				if(id){
					dispatch(toggleAdminEdit(id, false, false, true));
					dispatch(showMergeContactCardPopup(false));
					if(rs.success){
						dispatch(setAdminHighlight(true));
					}
					dispatch(sstAsyncs[M_CONTACT_CARDS].one(id))
					dispatch(sstAsyncs[M_CONTACT_CARDS].notes({groupid:id}))
					//color
					.then((rs) => {
						dispatch(clearPopWaiting());
						dispatch(toggleToastAlert(TOAST_TYPE.success, TOAST_POSITION.topRight, I(`Contact card ${sourceName} merged into ${targetName} successfully`)))
						//purposely delay UI highlight removal
						setTimeout(() => {
							dispatch(setAdminHighlight(false));
						}, 1000);
					})
				}else {
					dispatch(toggleAdminEdit(0,false));
					dispatch(clearPopWaiting());
				}
			})
			.catch(err => {
				console.log("error merging contact :", err)
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onToggleMergePopup: (toggle) => {
			dispatch(showMergeContactCardPopup(toggle));
		},
		onFetchAdminList: () => {
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(fetchAdminList(M_CONTACT_CARDS))
			dispatch(toggleAdminEdit(0,false));
			dispatch(clearPopWaiting());
		},
		onAddAccount: (view, params, id) => {
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(sstAsyncs[M_CONTACT_CARDS].save(params))
			.then((rs) => {
				dispatch(sstAsyncs[M_CONTACT_CARDS].one(id))
				dispatch(clearPopWaiting());
			})
			.catch(err => {
				console.log("error saving contact account :", err)
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onAddNote: (params, id) => {
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(sstAsyncs[M_CONTACT_CARDS].addNote(params))
			.then((rs) => {
				dispatch(sstAsyncs[M_CONTACT_CARDS].notes({groupid:id}))
				.then((rs)=>{
					dispatch(setContactCard("noteAttachment", []))
					dispatch(clearPopWaiting());
				})
			})
			.catch(err => {
				console.log("error saving contact note :", err)
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onDeleteNote: (params) => {
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(sstAsyncs[M_CONTACT_CARDS].deleteNote(params))
			.then((rs) => {
				dispatch(sstAsyncs[M_CONTACT_CARDS].notes({groupid: params.groupid}))
				.then((rs)=>{
					dispatch(setContactCard("noteAttachment", []))
					dispatch(clearPopWaiting());
				})
			})
			.catch(err => {
				console.log("error deleting contact note :", err)
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onClearAttachments: () => {
			dispatch(setContactCard("noteAttachment", []));
		},
		onFileupload: (file, route) => {
			dispatch(uploadClientNoteAttachment(file, route));
		},
		onAttachmentDelete: (type, attachmentID, index, e, attachmentFileName) => {
			confirmDeleteNoteAttachment(dispatch, type, attachmentID, attachmentFileName);
		},
		onDeleteFromList: (id, view, popout, list) => {
			dispatch(togglePopWaiting(pleaseWaitString(TXT_DELETING)));
			dispatch(sstAsyncs[M_CONTACT_CARDS].remove(id))
			.then((rs) => {
				dispatch(fetchAdminList(M_CONTACT_CARDS));
				//close form
				dispatch(toggleAdminEdit(0, false, false, true));
				dispatch(clearPopWaiting());
			})
			.catch(err => {
				console.log("error deleting contact :", err)
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onDeleteAccount: (accountId, id, list) => {
			dispatch(togglePopWaiting(pleaseWaitString(TXT_DELETING)));
			dispatch(sstAsyncs[M_CONTACT_CARDS].removeAccount(accountId))
			.then((rs) => {
				dispatch(sstAsyncs[M_CONTACT_CARDS].one(id))
				.then((rs)=>{
					dispatch(clearPopWaiting());
				})
			})
			.catch(err => {
				console.log("error deleting contact account: ", err);
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onDeleteAvatar: (id) => {
			dispatch(togglePopWaiting(pleaseWaitString(TXT_DELETING)));
			dispatch(sstAsyncs[M_CONTACT_CARDS].removeAvatar({id:id}))
			.then((rs) => {
				dispatch(sstAsyncs[M_CONTACT_CARDS].one(id))
					.then((rs) => {
						dispatch(clearPopWaiting());
					})
			})
			.catch(err => {
				console.log("error deleting contact avatar: ", err);
				dispatch(clearPopWaiting());
				return dispatch(popErrorOnly(err));
			});
		},
		onCloseForm: (id, view, popout, list) => {
			dispatch(resetAdminInput());
			dispatch(setContactCard("noteAttachment", []))
			dispatch(toggleAdminEdit(0, false, false, true));
			dispatch(toggleAdminFormType(FORM_EMPTY));
		},
		onShowOtherContact: (id, threadId) => {
			dispatch(reloadBasicErrand(id));
			dispatch(showOtherContactErrand(id, threadId));
		},
	}
};

const ContactCardsCtnr = connect(
	mapContactCardsState
	, mapContactCardsAction
)(ContactCards);

export default ContactCardsCtnr;
