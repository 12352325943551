import React, {
	useEffect,
	useState
} from 'react';
import { I } from '../../../common/v5/config';
import {
	ICON_CUSTOM_CHANNEL,
	CHANNEL_ICONS_MAP as iconMap
} from '../../../common/v5/constants';
import { TableIconicButton as Button } from '../../../reactcomponents/Form';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SquareCheckbox from '../../../reactcomponents/SquareCheckbox';
import { TABLE_STANDARD_OPTIONS } from '../../../reactcomponents/Table';

const getChannelIcon = (channel) => {
	let iconClass = iconMap[channel] || ICON_CUSTOM_CHANNEL
	return iconClass;
}

export const ContactCardsHistory = ({children}) => <div id="contactCardHistories" className='histories-section'>{children}</div>

export const ContactCardsHistoryList = ({
	errandList
 	, onShowOtherContact
	, onToggleCompanyErrand
	, ...props
}) => {
	const serviceFormatter = (cell, row) => {
		let service = row.serviceName
		return(
			<div className='custom-col service'>
				<i className={getChannelIcon(service)}></i>
				<span className='label'>{cell}</span>
			</div>

		)
	}

	const handleOpenErrand = (id,threadId) =>{
		onShowOtherContact(id, threadId)
	}

	const displayIdFormatter = (cell, row) => {
		let id = row.id
		let threadId = row.threadId
		return(
			<div className='custom-col displayId'>
				<div className='text-line-blue' style={{cursor: "pointer"}}>
					<a className="current"
						onClick={() => handleOpenErrand (id, threadId)}>
						<span className='label'>{cell}</span>
					</a>
				</div>
			</div>
		)
	}

	const handleAnonymizeErrand = (e, id, cipherKey) => {
		var ok = window.confirm(I("You are deleting the content of this errand. If you proceed there is no way of reversing the action"));
		if(ok) {
			//Tk-todo: checkback webserverRoot
			$.post(webserverRoot + "contact/anonymizeErrand", {
				errandId: id,
				cipher_key: cipherKey
			})
			.done(function(rs){
				if (rs && rs.result) {
					if (rs.result.id === 0 && rs.result.error != "") {
						props.onShowAlert(rs.result.error)
					} else {
						props.onShowAlert(I("Errand anonymized successfully"))
						props.refreshOtherContacts(props.currentErrandId);
					}
				}
			}.bind(this));
		}
	}

	const displayAnonymize = (cell, row) => {
		let oc;
		$.each(errandList, (i, v) => {
			if (v.id == row.id) {
				oc = v
			}
		})
		return (
			<Button data-qa-id={"QA_anonymize_errand "+row.id}
				title={I(`Anonymize errand ${row.id}`)}
				className="btn-red slim"
				type="button"
				label={I(`Anonymize`)}
				hidden={props.dataSrc.agentAnonymize || oc.anonymized}
				onClick={(e) => handleAnonymizeErrand(e, oc.id, oc.cipherKey)}
			/>
		)
	}
	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'serviceName',
			text: 'Channel',
			sort: true,
			formatter: serviceFormatter
		},
		{
			dataField: 'displayId',
			text: 'Errand',
			sort: true,
			formatter:(cell, row) => displayIdFormatter(cell, row)
		},
		{
			dataField: 'subject',
			text: 'Subject',
			sort: true,
		},
		{
			dataField: 'agent',
			text: 'Agent',
			sort: true,
		},
		{
			dataField: 'date',
			text: 'Arrived',
			sort: true,
		},
		{
			dataField: 'answered',
			text: 'Answered',
			sort: true,
			hidden: true,
		},
        {
			dataField: 'threadId',
			text: 'ThreadId',
			sort: true,
			hidden: true,
		},
		{
			dataField: 'otherContacts',
			text: 'Data Privacy',
			sort: false,
			hidden: props.dataSrc.agentAnonymize ? false : true,
			formatter:(cell, row) => displayAnonymize(cell, row)
		},
	];

	const defaultSorted = [{
		dataField: 'date',
		order: 'desc'
	}];

	const showCompanyInfo = props.input.showCompanyInfo;

	const [checkAnswered, setCheckAnswered] = useState(true);
	const [checkUnanswered, setCheckUnanswered] = useState(true);
	const [filteredErrandList, setFilteredErrandList] = useState(errandList);
	
	const filtering = (cb) =>{
		let newErrandList = []
		
		let displayAnswered = checkAnswered
		let displayUnanswered = checkUnanswered

		if (cb == "cbAnswered") {
			setCheckAnswered(!checkAnswered)
			displayAnswered = !checkAnswered
		} else {
			setCheckUnanswered(!checkUnanswered)
			displayUnanswered = !checkUnanswered
		}
		$.each(errandList, (i, v) => {
			if (v.answered == true && displayAnswered ) {
				newErrandList.push(v)
			}
			if (v.answered == false && displayUnanswered ) {
				newErrandList.push(v)
			}
		})
		setFilteredErrandList(newErrandList)
	}

	useEffect (() => {
		setFilteredErrandList(errandList);
	}, [errandList]);
	// #contactCardHistory will be use in scss
	const hasCompany = props.input.companyId;
	return (
		<div className='content-wrapper'>
			{props.errandMode &&
				<div id="ccToggleCompanyErrand">
					<Button data-qa-id={"cc_company_errand "}
						// id="ccToggleCompanyErrand"
						title={hasCompany ? I("Toggle company errand") : I("Toggle company errand (no company)")}
						className={"btn btn-toggle outline blue-transparent text-normal" + (showCompanyInfo ? " active" : "")}
						type="button"
						label={I("Company errand")}
						disabled={!hasCompany}
						onClick={()=> onToggleCompanyErrand(!showCompanyInfo)}
					/>
				</div>
			}
			<div className="dropdown-checkbox rounded">
				<SquareCheckbox
					className="answered-checkbox"
					label={I('Answered')}
					checked={checkAnswered}
					onClick={() =>{filtering("cbAnswered")}}
				/>
				<SquareCheckbox
					className="unanswered-checkbox"
					label={I('Unanswered')}
					checked={checkUnanswered}
					onClick={() =>{filtering("cbUnAnswered")}}
				/>
			</div>
			<div id="contactCardHistory" className="table-wrapper v5-table-style">
					<BootstrapTable
						id="ccHistoryTable"
						keyField="id"
						data={filteredErrandList}
						noDataIndication={I("No data to display")}
						columns={columns}
						bordered={false}
						condensed={false}
						defaultSorted={defaultSorted}
						pagination={ paginationFactory(TABLE_STANDARD_OPTIONS) }
						hover
						{...props.baseProps}
					/>
			</div>
		</div>
	)
}
