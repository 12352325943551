import React, {
	Fragment
	, PureComponent
	, memo
	, useMemo
	, useEffect
	, useState,
	useRef
} from 'react';
import { I, webRoot } from '../../../common/v5/config';
import { AreaDropdown, ActiveBorderHighlightArraySingleSelect as SingleSelect } from '../../../reactcomponents/Dropdown';
import {
	ADMIN_VIEW_MAP,
	INPUT_EMPTY_WARNING
	, FORM_SUBMIT_EMPTY_WARNING
	, TXT_SELECT_COMPANY
	, ICON_CUSTOM_CHANNEL
	, CHANNEL_ICONS_MAP as iconMap
} from '../../../common/v5/constants';
import { renderFileActionBtns as renderActionButtons } from '../admin';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { BootstrapSearchBar } from "./contactCards";
import { customPageTotal, TABLE_STANDARD_OPTIONS } from '../../../reactcomponents/Table';
import {
	TextInputRow,
	SelectInputRow,
	ReadOnlyTextField,
	CustomInputRow,
	TableIconicButton as Button
} from '../../../reactcomponents/Form';
import { withEditWrap } from '../../../reactcomponents/Admin';
import { DataProtectionCtnr } from '../errandCtnrs';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';
import {
	TabContent
	, TabPane
	, Nav
} from 'reactstrap';
import { OneNavItem } from '../../../reactcomponents/NavTabs';
import ContactCardsNotes from './contactCardsNotes'
import {ContactCardsHistory,
	ContactCardsHistoryList} from './contactCardsHistory'
import AvatarEditor from 'react-avatar-editor';
import { list } from '../../../redux/actions/async/agentAccessKey';
import CFlag from  '../../../reactcomponents/CFlag';
import { ReplyOptionButtonItem } from '../../../components/v5/Footers';
import SquareCheckbox from '../../../reactcomponents/SquareCheckbox';
const getChannelIcon = (channel) => {
	let iconClass = iconMap[channel] || ICON_CUSTOM_CHANNEL
	return iconClass;
}
import { getUniqueChannels } from '../../../redux/selectors/admin';
import { filePathPrefix } from '../../../common/v5/helpers';
const AvatarThumbnailEdit = ({input, activeId, editable, onUpload, onDeleteAvatar}) => {
	const [overlay, setOverlay] = useState(false);
	return (
		<div className='avatar-container'
			onMouseOver={() => setOverlay(true)}
			onMouseOut={() => setOverlay(false)}
		>
			{/* overlay tint here */}
			<div className='avatar-overlay' hidden={!overlay || !editable}>
				<button type="button" className='actions btn-file-upload-wrap'>
					<i className='icon-upload'></i>
					<input className='file-upload' type="file" onChange={onUpload}/>
				</button>
				<Button
					title={I("remove picture")}
					className={'actions ' + (!input.avatar && "disabled")}
					onClick={onDeleteAvatar}
					iconClass={"icon-trash"}
				/>
			</div>
			{input.avatar ?
			<img className='profile' src={input.avatar}></img>
			:
			<i className='icon-user-fill'></i>
			}
		</div>
	)
}

const ModalBox = ({
	show
	, className
	, onClose
	, children
}) => {
	return (
		<div className={`${className} popup `+ (show ? "open" : "")}>
			<div className='popup-inner'>
				<Button
					className={"popup-close btn-transparent m-0 p-1"}
					iconClass={"icon-times"}
					onClick={onClose}
					title={I("Close")}
				/>
				<div className='popup-content'>
					{children}
				</div>
			</div>
		</div>
	)
}

const ContactAvatar = ({
	input
	, defaultService
	, view
	, onChangeAdminInput
	, onSave
	, activeId
	, isNew
	, onDeleteAvatar
	, editable
	// ...props
}) => {
	const [popup, setPopup] = useState(false);
	const [imageUpload, setImageUpload] = useState("");

	const handleUploadImage = (e) => {
		setImageUpload(e.target.files[0])
		setPopup(true);
	}

	const handleDeleteAvatar = () => {
		if(activeId) {
			onDeleteAvatar(activeId)
		}
		//reset after delete
		onChangeAdminInput("avatar", "")
	}
	useEffect(()=> {
		if(!imageUpload) {
			setPopup(false)
		}
	},[imageUpload, setPopup]);
	return (
		<Fragment>
			<AvatarThumbnailEdit
				input={input}
				activeId={activeId}
				onUpload={handleUploadImage}
				onDeleteAvatar={handleDeleteAvatar}
				editable={editable}
			/>
			{popup && <ModalBox
				show={popup}
				onClose={()=>{setPopup(false)}}
				className={"avatar-custom-editor"}
			>
				<CustomAvatarEditor
					input={input}
					isNew={isNew}
					activeId={activeId}
					defaultService={defaultService}
					view={view}
					onChangeAdminInput={onChangeAdminInput}
					onSave={onSave}
					imageUpload={imageUpload}
					handleUploadImage={handleUploadImage}
					setPopup={setPopup}
				/>
			</ModalBox>}
		</Fragment>
	)
}

const CustomAvatarEditor = ({
	input
	, isNew
	, activeId
	, defaultService
	, view
	, onChangeAdminInput
	, onSave
	, imageUpload
	, handleUploadImage
	, setPopup
}) => {

	const [scale, setScale] = useState(1);
	const [previewImg, setPreviewImg] = useState(null);
	const [editor, setEditor] = useState(null);

	useEffect(()=> {
		//triggers after unmount (cleanup)
		return ()=> {
			//reset back preview
			setPreviewImg(null);
		}
	},[]);

	const dataURItoBlob = (dataURI) => {
		var byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(dataURI.split(',')[1]);
		else
			byteString = unescape(dataURI.split(',')[1]);

		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ia], {type:mimeString});
	}
	// const handleSetPreview = data => {
	// 	const img = this.editor.getImageScaledToCanvas().toDataURL()
	// 	const rect = this.editor.getCroppingRect()
	// 	setPreviewImg(img)
	// 	//means create New
	// 	// if (!activeId) {
	// 	// 	onChangeAdminInput("avatar", img);
	// 	// 	setPopup(false);
	// 	// }
	// }

	const postImage = (image) => {
		var fd = new FormData();
		var blob = dataURItoBlob(image);
		fd.append("uploadedAvatar", blob);
		$.ajax({
			url: filePathPrefix() + "errand/uploadClientAvatar",
			data: fd,
			processData: false,
			contentType: false,
			type: 'POST',
			success: function(rd){
				onChangeAdminInput("filename", rd.filename)
				onChangeAdminInput("filetype", rd.filetype)
				let params = {
					serviceType: defaultService, //todo: checkback if can ommit serviceType when saving
					groupId: input.id,
					groupName: input.name,
					companyId: input.company,
					city: input.city,
					postcode: input.PostCode,
					externalId: input.externalId,
					filename: rd.filename,
					filetype: rd.filetype
				}
				//if its not a new record, then we save to backend to overwrite existing
				if(!isNew){
					onSave(view, params, activeId);
				}
				setPopup(false)
			}
		});
	}

	const handleApplyImage = () => {
		const img = editor.getImageScaledToCanvas().toDataURL()
		const rect = editor.getCroppingRect()
		setPreviewImg(img)
		//means create New
		if (!activeId) {
			onChangeAdminInput("avatar", img);
			setPopup(false);
		}
		postImage(img);
	}

	const handleSaveImage = data => {
		const img = editor.getImageScaledToCanvas().toDataURL()
		const rect = editor.getCroppingRect()
		postImage(img, onSave);
	}
	return (
		<Fragment>
			<div className='avatar-canvas'>
				<AvatarEditor
					ref={setEditor}
					image={imageUpload}
					width={200}
					height={200}
					border={25}
					borderRadius={100}
					scale={parseFloat(scale)}
					color={[255, 255, 255, 0.6]} // RGBA
					rotate={0}
				/>
				<div className='file-upload-wrap'>
					<div className='label'>
						<i className='icon-add'></i>
						<span>{I("Upload a new photo")}</span>
					</div>
					<input className='file-upload' type="file" onChange={handleUploadImage}/>
				</div>
			</div>
			<div className='config-sliders'>
				<h3>({I("Scale")})</h3>
				<input name="scale" type="range" min="1" max="2" step="0.01" value={scale} onChange={(e) => setScale(e.target.value)} />
			</div>
			<img style={{borderRadius:"50%"}} src={previewImg ? previewImg : ""}/>
			{!activeId ?
				<Button className='btn-blue save' type='button' onClick={handleApplyImage} title="Apply" data-qa-id="btn-apply-avatar" label={I("Apply")}/>
			:
				<Button className='btn-blue save m-0' type='button' onClick={handleSaveImage} title="Add" data-qa-id="btn-save-avatar" label={I("Save")}/>
			}
		</Fragment>
	)
}

const ContactSocialIcons = ({
	channels
}) => {
	let icons = [];
	if (channels && channels.length > 0) {
		icons = channels.map((channel, i) => {
			return <i key={i} title={channel} className={getChannelIcon(channel)}></i>
		})
	}
	return (
		<div className='icons-section'>
			{icons}
		</div>
	)
}

const warnTxt = INPUT_EMPTY_WARNING;

const ContactInputs = ({
	input
	, companyList
	// , company
	, onChange
	, onChangeAdminInput
}) => {
	const [touched, setTouched] = useState(false);
	return (
		<div className="input-wrapper">
			<TextInputRow
				id={"name"}
				name={"name"}
				placeholder={I("Contact name*")}
				className="admin-text-input"
				value={input.name || ""}
				onChange={onChange}
				onBlur={()=>setTouched(true)}
				mandatory={true}
				warning={(!input.name && touched) && warnTxt}
			/>
			<TextInputRow
				id={"city"}
				name={"city"}
				placeholder={I("City")}
				className="admin-text-input"
				value={input.city || ""}
				onChange={onChange}
			/>
			<TextInputRow
				id={"PostCode"}
				name={"PostCode"}
				placeholder={I("Postcode")}
				className="admin-text-input"
				value={input.PostCode || ""}
				onChange={onChange}
			/>
			<TextInputRow
				id={"externalId"}
				name={"externalId"}
				placeholder={I("External ID")}
				className="admin-text-input"
				value={input.externalId || ""}
				onChange={onChange}
			/>
			<CustomInputRow
				id={"company"}
				name={"company"}
				className={"admin-select-option"}
				mandatory={true}
			>
				<SingleSelect
					data={companyList}
					invalidSelectedText={TXT_NO_SELECTION}
					textNoItemSelected={TXT_SELECT_COMPANY}
					onSelect={(v) => onChangeAdminInput("company", v)}
					selected={input.company || ""}
					idFields={{ id: 'id', name: 'name' }}
				/>
			</CustomInputRow>
		</div>
	)
}

const NewAccountForm = ({
	input
	, activeId
	, dataSrc
	, onChange
	, onChangeAdminInput
	, onSave
	, onCancel
	, view
	, isChat
	, defaultService
	, disableAccountTextInput
	, disableAccountSelectInput
}) => {
	const [touched, setTouched] = useState(false);
	const [serviceType, setServiceType] = useState(input.serviceType);
	const handleSave = () => {
		let params = {
			groupId: activeId,
			groupName: input.name,
			city: input.city,
			postcode: input.PostCode,
			externalId: input.externalId,
			serviceType: serviceType,
			contact: input.contact,
			filename: input.filename,
			filetype: input.filetype,
			companyId: input.company
		}
		onSave(view, params);
	}

	let isValid = false;
	if(input.name && input.contact){
		isValid = true
	}

	return (
		<div id="contactCardNewAccount" className="form-container clearfix">
			<div className="input-wrapper">
				<CustomInputRow
					id={"serviceType"}
					name={"serviceType"}
					className={"admin-select-option cc-service"}
				>
					<SingleSelect
						data={dataSrc.channel}
						invalidSelectedText={TXT_NO_SELECTION}
						onSelect={(v) => setServiceType(v)}
						selected={serviceType}
						disabled={disableAccountSelectInput}
						idFields={{ id: "type", name: "name" }}
					/>
				</CustomInputRow>
				<TextInputRow
					disabled={disableAccountTextInput}
					id={"contact"}
					name={"contact"}
					className="admin-text-input cc-name"
					value={input.contact || ""}
					onChange={onChange}
					onBlur={()=>setTouched(true)}
					mandatory={true}
					warning={(!input.contact && touched) && warnTxt}
				/>
			</div>
			<div className='action-wrapper'>
				<Button type="button" onClick={onCancel} className="btn-grey" title="Cancel" data-qa-id="btn-cancel-account" label={I("Cancel")}/>
				<Button type="button" onClick={handleSave} disabled={!isValid} className="btn-blue" title="Save" data-qa-id="btn-save-account" label={I("Save")}/>
			</div>
		</div>
	)
}

const EditContact = ({children}) => <Fragment>{children}</Fragment>

const EditableContactInputs = ({
	input
	, companyList
	, onSave
	, view
	, onTextInputChange
	, onChangeAdminInput
	, defaultService
	, activeId
	, editable
}) => {

	const [editing, setEditing] = useState(false);
	const [name, setName] = useState(input.name);
	const [company, setCompany] = useState(input.company);
	const actionIcon = editing ? "icon-times" : "icon-edit";

	const handleSave = () => {
		//matches with backend name
		let params = {
			serviceType: defaultService, //todo: checkback if can ommit serviceType when saving
			groupId: input.id,
			groupName: input.name,
			companyId: input.company,
			city: input.city,
			postcode: input.PostCode,
			externalId: input.externalId
		}
		onSave(view, params, activeId);
		setEditing(false);
	}

	// let companyName = companyList.find(c => c.id === input.company);

	return (
		<div className='editable-content'>
			{editing ?
				<ContactInputs
					input={input}
					companyList={companyList}
					onChange={onTextInputChange}
					onChangeAdminInput={onChangeAdminInput}
				/>
				:
				<div className='details-wrapper'>
					<h2 className='name-heading'>{input.name}</h2>
					<div className='company-label'>{input.companyName}</div>
					{/* <div className='company-label'>{companyName.name || input.companyName}</div> */}
				</div>
			}
			<div hidden={!editable} className='action-wrapper'>
				<i onClick={() => setEditing(!editing)} className={actionIcon}></i>
				<Button type="button" onClick={handleSave} disabled={!input.name} hide={!editing} className="btn-blue save" title="Save" data-qa-id="btn-save-contact" label={I("Save")}/>
			</div>
		</div>
	)
}

const ContactCardsAccount = ({children}) => <div id="contactCardAccounts" className='accounts-section'>{children}</div>

const AccountForm = ({
	input
	, dataSrc
	, companyList
	, onSave
	, onAddAccount
	, view
	, activeId
	, ...props
}) => {

	const [editing, setEditing] = useState(false);
	const [touched, setTouched] = useState(false);
	const [contact, setContact] = useState("");
	const [customService, setCustomService] = useState("");
	const [serviceType, setServiceType] = useState(props.defaultService);

	const handleSave = () => {
		//ENSURE field name matches with backend name
		let params = {
			serviceType: serviceType, //todo: checkback if can ommit serviceType when saving
			customService: customService,
			groupId: input.id,
			groupName: input.name,
			city: input.city,
			postcode: input.PostCode,
			externalId: input.externalId,
			companyId: input.company,
			contact: contact
		}
		onAddAccount(view, params, activeId);
		setContact("");
		setCustomService("");
		setTouched(false);
		setEditing(false);
	}

	let valid = true;
	if (serviceType === 0 && !customService || !contact) {
		valid = false;
	}

	return (
		<div className='account-form-addition'>
			{editing ?
			<div className='form-container clearfix'>
				<div className="input-wrapper">
					<CustomInputRow
						id={"serviceType"}
						name={"serviceType"}
						className={"admin-select-option cc-services"}
					>
						<SingleSelect
							data={dataSrc.channel}
							invalidSelectedText={TXT_NO_SELECTION}
							onSelect={(v) => setServiceType(v)}
							selected={serviceType}
							idFields={{id: "type", name: "name"}}
						/>
						<TextInputRow
							id={"customService"}
							name={"customService"}
							hide={serviceType !== 0}
							placeholder={I("Label")}
							className="admin-text-input cc-customService"
							value={customService}
							onChange={(e) => setCustomService(e.target.value)}
							onBlur={()=>setTouched(true)}
							mandatory={true}
							warning={(!customService && touched) && warnTxt}
						/>
					</CustomInputRow>
					<TextInputRow
						id={"name"}
						name={"name"}
						className="admin-text-input cc-name"
						value={contact}
						onChange={(e) => setContact(e.target.value)}
						onBlur={()=>setTouched(true)}
						mandatory={true}
						warning={(!contact && touched) && warnTxt}
					/>
				</div>
				<div className='action-wrapper'>
					<Button type="button" onClick={() => setEditing(false)} className="btn-grey" title="Cancel" data-qa-id="btn-cancel-account" label={I("Cancel")}/>
					<Button type="button" onClick={handleSave} disabled={!valid} className="btn-blue save" title="Save" data-qa-id="btn-add-account" label={I("Add contact")}/>
				</div>
			</div>
			:
			<Button type="button"
				className="btn-blue add btn-round"
				onClick={() => setEditing(true)}
				title={I("Add")}
				data-qa-id="btn-toggle-account-form"
				iconClass='icon-add'
			/>
			}
		</div>
	)
}

const ContactAccountList = ({
	list
	, onDeleteAccount
	, activeId
	, onLoadToEdit
	, editable
	, itemsPerPage = 5
}) => {
	const handleDeleteAccount = (accountId,p2,p3,p4) => {
		let confirmDelMsg = I('Are you sure you want to delete?');

		if (confirm(confirmDelMsg)) { // TODO: avoid using primitive confirm box
			onDeleteAccount(accountId, activeId, list);
		}
	}

	const serviceFormatter = (cell, row) => {
		let service = row.service
		return(
			<div className='custom-col service'>
				<i className={getChannelIcon(service)}></i>
				<span className='label'>{cell}</span>
			</div>

		)
	}

	const actionDatas = {
		// onDownload: onDownloadFromList,
		onDelete: handleDeleteAccount,
		// onEdit: onLoadToEdit
		// selectedAreas: [1, 23, 4]
	}
	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'service',
			text: 'Accounts',
			sort: true,
			formatter: serviceFormatter
		},
		{
			dataField: 'address',
			text: 'Contact ID',
			sort: true,
		},
		{
			isDummyField: true,
			dataField: 'action',
			text: 'Action',
			headerClasses: 'center',
			formatter: (cell, row) => {
				return renderActionButtons(cell, row, actionDatas);
			},
			hidden: !editable
		}
	];

	const defaultSorted = [{
		dataField: 'createtime',
		order: 'desc'
	}];

	const options = {
		paginationSize: 3,
		pageStartIndex: 1,
		hideSizePerPage: true, // Hide the sizePerPage dropdown always
		hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
		firstPageText: '<<',
		prePageText: '<',
		nextPageText: '>',
		lastPageText: '>>',
		showTotal: true,
		paginationTotalRenderer: customPageTotal,
		disablePageTitle: true,
		sizePerPageList: [itemsPerPage]
	};

	return (
		<div className="table-wrapper v5-table-style">
			<ToolkitProvider
				keyField="id"
				data={ list }
				columns={ columns }
				search
				>
				{
					props => (
						<BootstrapTable
							keyField="id"
							data={list}
							noDataIndication={I("No data to display")}
							columns={columns}
							bordered={false}
							condensed={false}
							defaultSorted={defaultSorted}
							pagination={ paginationFactory(options) }
							hover
							{...props.baseProps}
						/>
					)
				}
			</ToolkitProvider>
		</div>
	)
}

const ContactTabs = (props) => {
	const TAB_NOTE = 1
		, TAB_ACCOUNT = 2
		, TAB_HISTORY = 3
		, TAB_DATA_PROTECTION = 4

	const [activeTab, setActiveTab] = useState(TAB_ACCOUNT);

	let list = props.dataSrc.list;
	let errandList = props.dataSrc.errandList;
	let hideNoteTab = props.isNew;
	if (props.isNew && props.errandMode) {
		hideNoteTab = false;
	}
	return (
		<section className={"contact-tabs"}>
			<Nav tabs>
				<OneNavItem
					active={activeTab === TAB_NOTE}
					onClick={(v) => {setActiveTab(v)}}
					tabId={TAB_NOTE}
					text={I("Notes")}
					// disabled={props.isNew}
					hidden={hideNoteTab}
				/>
				<OneNavItem
					active={activeTab === TAB_ACCOUNT}
					onClick={(v) => {setActiveTab(v)}}
					tabId={TAB_ACCOUNT}
					text={I("Accounts")}
				/>
				<OneNavItem
					active={activeTab === TAB_HISTORY}
					onClick={(v) => {setActiveTab(v)}}
					tabId={TAB_HISTORY}
					text={I("History")}
				/>

				{props.errandMode &&
					<OneNavItem
						active={activeTab === TAB_DATA_PROTECTION}
						onClick={(v) => {setActiveTab(v)}}
						tabId={TAB_DATA_PROTECTION}
						text={I("Data Privacy")}
					/>
				}
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId={TAB_NOTE}>
					<ContactCardsNotes {...props} />
				</TabPane>
				<TabPane tabId={TAB_ACCOUNT}>
					{props.isNew ?
						<NewAccountForm onChange={props.onTextInputChange} {...props}/>
						:
						<ContactCardsAccount>
							<AccountForm {...props}/>
							{list && <ContactAccountList list={list} {...props}/>}
						</ContactCardsAccount>
					}
				</TabPane>

				<TabPane tabId={TAB_HISTORY}>
					<ContactCardsHistory>
						{errandList && <ContactCardsHistoryList errandList={errandList} {...props}
						/>}
					</ContactCardsHistory>
				</TabPane>
				{props.errandMode &&
					(props.showCC && (props.agentAnonymize || props.dataPortabilityExport) && !props.isChat) &&
					<TabPane tabId={TAB_DATA_PROTECTION}>
						<DataProtectionCtnr expand={()=>{}} {...props} />
					</TabPane>
				}
			</TabContent>
		</section>
	)
}

const ContactOverview = ({
	input
	, defaultService
	, view
	, activeId
	, isNew
	, companyList
	, onTextInputChange
	, onChangeAdminInput
	, onDeleteAvatar
	, onSave
	, editable
}) => {
	return (
		<div className='contact-overview'>
			<section className='avatar-section'>
				<ContactAvatar
					input={input}
					defaultService={defaultService}
					view={view}
					onChangeAdminInput={onChangeAdminInput}
					activeId={activeId}
					onSave={onSave}
					isNew={isNew}
					onDeleteAvatar={onDeleteAvatar}
					editable={editable}
				/>
			</section>
			<section className="details-section">
				{isNew ?
				<ContactInputs
					input={input}
					companyList={companyList}
					onChange={onTextInputChange}
					onChangeAdminInput={onChangeAdminInput}
				/>
				:
				<EditContact>
					<EditableContactInputs
						input={input}
						companyList={companyList}
						// onSave={onSaveAdminParams}
						onSave={onSave}
						view={view}
						isNew={isNew}
						onTextInputChange={onTextInputChange}
						onChangeAdminInput={onChangeAdminInput}
						defaultService={defaultService}
						activeId={activeId}
						editable={editable}
					/>
					<div className='text-label'>{I("Contact since") + " " + input.date}</div>
					<ContactSocialIcons channels={input.channels} />
				</EditContact>}
			</section>
		</div>
	)
}

const ContactList = ({onSelectList}) => {
	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'name',
			text: 'Name',
			sort: true,
		},
		{
			dataField: 'searchTerm',
			text: 'Keywords',
			sort: true,
			hidden: true
		},
	]

	const [contactList, setContactList] = useState([])

	useEffect(()=> {
		const fetchData = async () => {
			try {
				const response = await fetch(webRoot + `admin/contactcards/`);
				let jsonData = await response.json();
				setContactList(jsonData);
				// setData(jsonData);
			} catch (error) {
				console.error(error);
			}
		};
		fetchData();
		return () => {};
	}, [])

	const selectRow = {
		mode: 'radio',
		clickToSelect: true,
		hideSelectColumn: true,
		classes: 'active',
		onSelect: (row, isSelect, rowIndex, e) => {
			if(isSelect) {
				onSelectList(e, row.id)
				console.log("selected");
			} else {
				console.log("already selected");
			}
		}
	};

	return (
		<ToolkitProvider
			keyField="id"
			data={ contactList }
			columns={ columns }
			search
			>
			{props => (
				<div className='contact-list'>
					<BootstrapSearchBar
						searchProps={props.searchProps}
						className="search-field-text"
						placeholder={I("Search by name, email or phone number")}
						icon="icon-search"
					/>
					<br/>
					<div className='merge-contact__list table-wrapper v5-table-style'>
						<BootstrapTable
							classes="table-wrapper"
							keyField="id"
							data={contactList}
							noDataIndication={I("No data to display")}
							columns={columns}
							selectRow={selectRow}
							headerClasses="d-none"
							bordered={false}
							condensed={false}
							// defaultSorted={defaultSorted}
							pagination={ paginationFactory(TABLE_STANDARD_OPTIONS) }
							hover
							{...props.baseProps}
						/>
					</div>
				</div>
			)}
		</ToolkitProvider>
	)
}

const MergeContactContainer = ({
	input
	, defaultService
	, view
	, activeId
	, companyList
	, onTextInputChange
	, onChangeAdminInput
	, onDeleteAvatar
	, onSave
	, accountList
	, onMergeContactCards
}) => {

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);

	const abortControllerRef = useRef(null); //prevent spamming fetch requests

	const sourceId = activeId;
	const sourceName = input.name
	const targetId = data && data.customer && data.customer.id;
	const targetName = data && data.customer && data.customer.name;

	const fetchData = async (id) => {
		setLoading(true);
		abortControllerRef.current = new AbortController();
		setData(null); //reset back
		try {
			const response = await fetch(webRoot + `admin/contactcards/${id}`);
			let jsonData = await response.json();
			const channels = getUniqueChannels(jsonData.list);
			if(Object.hasOwn(jsonData, "customer")) {
				jsonData.customer.channels = channels;
			}
			setData(jsonData);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleFetchContactInfo = (event, id) => {
		event.preventDefault(event);
		if(!loading) {
			if(abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
			fetchData(id);
		}
	}

	const handleMergeContactCards = () => {
		let confirmDelMsg = I('Are you sure you want to merge these contact cards? This action cannot be undone.');
		let listStr = accountList.map((item) => item.id).join(",");
		let params = {
			sourceId: sourceId,
			targetId: targetId,
			sourceList: listStr
		}
		if (confirm(confirmDelMsg)) { // TODO: avoid using primitive confirm box
			onMergeContactCards(view, params, targetId, sourceName, targetName);
		}
	}

	let btnTitle = targetName ?
	I('Merge into contact card {targetName}').replace('{targetName}', targetName)
	: I('Please select a contact card to merge');

	if(sourceId === targetId ) {
		btnTitle = I('Cannot merge into the same contact card');
	}

	return (
		<div id="contactCardMerge" className='merge-contact__wrapper'>
			<section className='contact-list__segment'>
				<ContactList onSelectList={handleFetchContactInfo}/>
			</section>
			{/* <hr className='merge-contact__divider'/> */}
			<section className='contact-overview__segment'>
				<div className='contact-overview__wrapper'>
					<ContactOverview
						input={input}
						defaultService={defaultService}
						view={view}
						activeId={activeId}
						isNew={false}
						companyList={companyList}
						onTextInputChange={onTextInputChange}
						onChangeAdminInput={onChangeAdminInput}
						onDeleteAvatar={onDeleteAvatar}
						onSave={onSave}
						editable={false}
					/>
					<br/>
					{accountList && <ContactAccountList
						list={accountList}
						onDeleteAccount={()=>{}}
						activeId={activeId}
						editable={false}
					/>}
				</div>
				<br/>
				<Button
					type="button"
					iconClass={"icon-merge-right"}
					iconSize={"20px"}
					onClick={handleMergeContactCards}
					disabled={!data || sourceId === targetId}
					hide={false}
					className="btn-blue merge-contact"
					title={btnTitle}
					data-qa-id="btn-merge-contact"
					label={I("Merge")}
				/>
			</section>
			{/* <hr className='merge-contact__divider'/> */}
			<section className='contact-overview__segment'>
				{loading && !data && <p>Loading...</p>}
				{data &&
				<div className='contact-overview__wrapper'>
					<ContactOverview
						input={data.customer}
						defaultService={defaultService}
						view={view}
						isNew={false}
						companyList={companyList}
						editable={false}
					/>
					<br/>
					{accountList && <ContactAccountList
						list={data.list}
						onDeleteAccount={()=>{}}
						activeId={activeId}
						editable={false}
					/>}
				</div>}
			</section>

		</div>
	)
}

const ContactCardsEditForm = ({
	hidden
	, input
	, activeId
	, view
	, isNew
	, dataSrc
	, companyList
	, onChangeAdminInput
	// , onSaveAdminParams
	, onSave
	, onAddAccount
	, onTextInputChange
	, defaultService
	, onDeleteAccount
	, onDeleteAvatar
	, notes
	, onAddNote
	, onDeleteNote
	, onShowOtherContact
	, onMergeContactCards
	, showMergeContactPopup
	, onToggleMergePopup
	, highlight
	, errandMode
	, ...props
}) => {

	let hideMergeBtn = false;
	const hideDeleteContactCardBtn = errandMode ? true : false;

	const hideEditForm = errandMode && showMergeContactPopup;

	const MergeContactCard = <MergeContactContainer
									input={input}
									defaultService={defaultService}
									view={view}
									activeId={activeId}
									companyList={companyList}
									onTextInputChange={onTextInputChange}
									onChangeAdminInput={onChangeAdminInput}
									onDeleteAvatar={onDeleteAvatar}
									onSave={onSave}
									accountList={dataSrc.list}
									contactCardList={props.contactCardList}
									onMergeContactCards={onMergeContactCards}
									eid={props.eid}
								/>

	return (
		<>
		<form id="contactCardEdit" className={"admin-one-form edit-admin-form " + (highlight ? "highlight" : "")} hidden={hidden || hideEditForm}>
			<ContactOverview
				input={input}
				defaultService={defaultService}
				view={view}
				activeId={activeId}
				isNew={isNew}
				companyList={companyList}
				onTextInputChange={onTextInputChange}
				onChangeAdminInput={onChangeAdminInput}
				onDeleteAvatar={onDeleteAvatar}
				onSave={onSave}
				editable={true}
			/>
			{/* tabbed section */}
			<ContactTabs
				input={input}
				activeId={activeId}
				dataSrc={dataSrc}
				onSave={onSave}
				view={view}
				onChangeAdminInput={onChangeAdminInput}
				onTextInputChange={onTextInputChange}
				isNew={isNew}
				defaultService={defaultService}
				onDeleteAccount={onDeleteAccount}
				onAddAccount={onAddAccount}
				notes={notes}
				onAddNote={onAddNote}
				onDeleteNote={onDeleteNote}
				onShowOtherContact={onShowOtherContact}
				editable={true}
				errandMode={errandMode}
				{...props}
			/>
			<div hidden={isNew} className='contact-actions'>
				{hideMergeBtn ? null :
				<CFlag flag="2023-05-18.CCC-5395.merge.contact.cards">
					<Button
						title={I("Merge contact card")}
						label={I("Merge contact card")}
						className={'btn-text text-normal blue-hover'}
						onClick={()=>{onToggleMergePopup(true)}}
						iconClass={"icon-merge"}
						iconPosition={"left"}
					/>
				</CFlag>
				}
				{hideDeleteContactCardBtn ? null :
				<Button
					title={I("Delete contact card")}
					label={I("Remove contact card")}
					className={'btn-text text-normal red-hover'}
					onClick={() => {props.onDelete(activeId)}}
					iconClass={"icon-trash"}
					iconPosition={"left"}
				/>
				}
			</div>
		</form>
			{/*merge contact*/}
			<CFlag flag="2023-05-18.CCC-5395.merge.contact.cards">
				{errandMode ?
				showMergeContactPopup && MergeContactCard
				:
				showMergeContactPopup && <ModalBox
					show={showMergeContactPopup}
					onClose={()=>{onToggleMergePopup(false)}}
					className={"merge-contact"}
				>
					{MergeContactCard}
				</ModalBox>}
			</CFlag>
		</>
	)
}

export default ContactCardsEditForm;

export const ContactCardsEdit = withEditWrap(ContactCardsEditForm);
