import { connect } from 'react-redux';
import InternalChat from './chat';
import {
	activateChat
} from '../../redux/actions/hmf';
import {
	// todo
	// acquireErrand,
	// doSend,
	// getMcountAction,
	// newSessionReceived,
	// onAcceptChat,
	onActivate,
	// onAgentPresence,
	// onAssociateErrand,
	// onClientPaths,
	// onConnectStatus,
	// onDeadSession,
	// onMessageAcked,
	// onPreview,
	onQueueSend,
	// onTags,
	onUnsentMessages,
	// onUpdateTags,
	// showSystemErrorMessage,
	// updateClientStatus,
	updateMessage,
	updateNewMessageStatus,
	resetNewMessageCount,
	updateShowConversations,
	updateShowFavourites,
	updateShowAll
} from '../../redux/actions/chat';
import {
	acceptInternalChat,
	// todo
	// onSessionInit,
	// onFinishSession,
	fetchChatAgentList,
	setFavouriteChatAgent,
	updateAnswerInvite
} from '../../redux/actions/async/chat';
import {
	getNewChatMessageCount,
	getFavouriteChatAgents,
	getChatAgents,
	getChatSessionList,
	getChannelsInfo
} from '../../redux/selectors/chat';
import {
	createErrandNote,
} from '../../redux/actions/async/errand.js';
import {
	inputTextChanges,
	selectShowReply,
} from '../../redux/actions/errand';
import { RPLY_ERRAND } from '../../common/v5/constants';

const mapStateToProp = store => {
	const s = {
		chatSessions: store.chat.socket.chatSessions,
		chatSessionList: getChatSessionList(store),
		agentPresence: store.chat.socket.agentPresence,
		acceptInternalChat: store.chat.socket.acceptInternalChat,
		connectStatus: store.app.header.uiData.wsConnected,
		activateChat: store.app.footer.uiData.activateChatWindow,
        newMessageCount: getNewChatMessageCount(store),
		favourites: getFavouriteChatAgents(store),
		agentList: getChatAgents(store),
		channelList: getChannelsInfo(store),
		ui: store.chat.ui,
		errandInputs: store.app.errand.inputs,
		videoCallFullScreen: store.app.errand.chat ? store.app.errand.chat.videoCallFullScreen : false
	};
	return s;
};

const mapDispatchToProp = (dispatch) => {
	return {
		// todo
		// acquireErrand: (p) => {
		// 	dispatch(acquireErrand(p));
		// },
		// doSend: (p) => {
		// 	dispatch(doSend(p));
		// },
		// getMcountAction: (sessionId) => {
		// 	dispatch(getMcountAction(sessionId));
		// },
		// newSessionReceived: (p) => {
		// 	dispatch(newSessionReceived(p));
		// },
		onAcceptChat: (p) => {
			dispatch(acceptInternalChat(p));
		},
		onActivate: (p) => {
			dispatch(onActivate(p));
		},
		// onAgentPresence: (p) => {
		// 	dispatch(onAgentPresence(p));
		// },
		// onAssociateErrand: (p) => {
		// 	dispatch(onAssociateErrand(p));
		// },
		// onClientPaths: (p) => {
		// 	dispatch(onClientPaths(p));
		// },
		// onConnectStatus: (p) => {
		// 	dispatch(onConnectStatus(p));
		// },
		// onDeadSession: (p) => {
		// 	dispatch(onDeadSession(p));
		// },
		// onMessageAcked: (p) => {
		// 	dispatch(onMessageAcked(p));
		// },
		// onPreview: (p) => {
		// 	dispatch(onPreview(p));
		// },
		onQueueSend: (p) => {
			dispatch(onQueueSend(p));
		},
		// onTags: (p) => {
		// 	dispatch(onTags(p));
		// },
		onUnsentMessages: (p) => {
			dispatch(onUnsentMessages(p));
		},
		// onUpdateTags: (p) => {
		// 	dispatch(onUpdateTags(p));
		// },
		// showSystemErrorMessage: (p) => {
		// 	dispatch(showSystemErrorMessage(p));
		// },
		// updateClientStatus: (p) => {
		// 	dispatch(updateClientStatus(p));
		// },
		onUpdateMessage: (p) => {
			dispatch(updateMessage(p));
		},
		// onSessionInit: (p) => {
		// 	dispatch(onSessionInit(p));
		// },
		// onFinishSession: (p) => {
		// 	dispatch(onFinishSession(p));
		// },
		onToggleChatWindow: () => {
			dispatch(activateChat());
		},
		onFetchAgent: (p) => {
			dispatch(fetchChatAgentList(p));
		},
		onUpdateFavourite: (p) => {
			dispatch(setFavouriteChatAgent(p));
		},
		onUpdateAnswerInvite: (p) => {
			dispatch(updateAnswerInvite(p));
		},
		onShowConversations: (show) => {
			dispatch(updateShowConversations(show));
		},
		onShowFavourites: (show) => {
			dispatch(updateShowFavourites(show));
		},
		onShowAll: (show) => {
			dispatch(updateShowAll(show));
		},
		onUpdateNewMessageStatus: (sessionId, status) => {
			dispatch(updateNewMessageStatus(sessionId, status));
		},
		onResetNewMessageCount: (sessionId) => {
			dispatch(resetNewMessageCount(sessionId));
		},
		onCreateErrandNote: (text) => {
			dispatch(createErrandNote(text));
		},
		onCopyErrandMessage: (html, plainText) => {
			dispatch(inputTextChanges('update_answer', html, plainText));
			dispatch(selectShowReply(RPLY_ERRAND, true));
		}
	}
};

const InternalChatCtnr = connect(mapStateToProp, mapDispatchToProp)(InternalChat);

export default InternalChatCtnr;
