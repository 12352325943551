import React, {
	Fragment
	, PureComponent
	, memo
	, useMemo
	, useEffect
	, useState,
	useRef
} from 'react';
import { I, webRoot } from '../../../common/v5/config';
import { AreaDropdown, ActiveBorderHighlightArraySingleSelect as SingleSelect } from '../../../reactcomponents/Dropdown';
import {
	ADMIN_VIEW_MAP,
	INPUT_EMPTY_WARNING
	, FORM_SUBMIT_EMPTY_WARNING
	, CHANNEL_ICONS_MAP as iconMap
} from '../../../common/v5/constants';
import { renderFileActionBtns as renderActionButtons } from '../admin';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
	TextInputRow,
	SelectInputRow,
	ReadOnlyTextField,
	CustomInputRow,
	TableIconicButton
} from '../../../reactcomponents/Form';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';
import {
	TabContent
	, TabPane
	, Nav
} from 'reactstrap';
import { OneNavItem } from '../../../reactcomponents/NavTabs';
import FileUploader from '../../../components/v5/FileUploader';
import { SerialTagList } from '../../../components/v5/Attachments';


const warnTxt = INPUT_EMPTY_WARNING;

const MoreActions = ({
	show
	, id
	, onToggle
	, onEdit
	, onDelete
	, editable
}) => {
	let menuRef = useRef();

	const [isOpen, setIsOpen] = useState(false);

	useEffect(()=>{
		let handler = (event) => {
			if(!menuRef.current.contains(event.target)){
				setIsOpen(false)
			}
		};
		document.addEventListener("mousedown", handler)

		return () => {
			document.removeEventListener("mousedown", handler)
		};
	});

	const handleEdit = () => {
		onEdit()
		setIsOpen(false)
	}

	return (
		<div ref={menuRef} className='more-actions'>
			<div className='toggle-wrapper'>
				<i className='icon-spread' onClick={()=> setIsOpen(!isOpen)}></i>
			</div>
			<div className='panels' hidden={!isOpen}>
				<ul className='options-wrapper'>
					{editable &&
					<li className='option-list edit' onClick={handleEdit}>
						<i className='icon-pencil'></i>
						<span>{I("Edit")}</span>
					</li>
					}
					<li className='option-list delete' onClick={onDelete}>
						<i className='icon-trash'></i>
						<span className='label'>{I("Delete")}</span>
					</li>
				</ul>
			</div>
		</div>
	)
}

const CardNotes = ({
	attachments
	, avatar
	, id
	, note
	, username
	, writtenDate
	, onEdit
	, onDelete
	, disableEditNote
}) => {
	let attachmentLinks = [];
	if (attachments !== undefined || attachments.length != 0) {
		attachmentLinks = attachments.map((attachment, i) => {
			return (
				<div key={i} className="customer-note-attachment">
					<i className="icon-attachment"></i>
					<a className="link" href={attachment.downloadURL} target="_blank" data-qa-id={attachment.fileName}>{attachment.fileName}</a>
				</div>
			)
		})
	}
	return (
		<div className="notes-card">
			<div className="card-heading">
				<div className='avatar-col'>
					{avatar ?
						<img src={avatar}></img>
						:
						<i className='icon-user-outline'></i>
					}
				</div>
				<div className="heading-col">
					<h5 className="title">{username}</h5>
					<p className="date">{writtenDate}</p>
				</div>
			</div>
			<div className='card-content'>
				<div className="description">{note}</div>
				<div className="attachments">
					{attachmentLinks}
				</div>
			</div>
			<div className='card-footer'>
				{features["notes.client.agent-edit"]
				 && <MoreActions id={id}
					onEdit={onEdit}
					onDelete={onDelete}
					show={true}
					editable={!disableEditNote}
				/>}
			</div>
		</div>
	)
}

const ContactCardsNotes = ({
	notes
	, onAddNote
	, activeId
	, onFileupload
	, onAttachmentDelete
	, noteAttachment
	, onClearAttachments
	, onDeleteNote
	, disableEditNote
}) => {

	const [showForm, setShowForm] = useState(false);
	const [note, setNotes] = useState("");
	const [noteId, setNoteId] = useState("");

	const handleAttachments = (dataFiles, uploadTo) => {
		onFileupload(dataFiles[0], uploadTo);
	}
	const handleNoteForm = (show, notes, notesId) => {
		//if not specified then default to empty (reset)
		let nt = notes ? notes : ""
		, id = notesId ? notesId : "";
		setShowForm(show);
		setNotes(nt);
		setNoteId(id);
		onClearAttachments();
	}
	const handleDelete = (id) => {
		let params = {id: id, type: "contactcard", groupid: activeId}
		onDeleteNote(params);
		handleNoteForm(false);
	}
	const handleSave = () => {

		let attachmentList = noteAttachment[0].list
		let attachment = [];

		$.each(attachmentList, (i, k)=> {
			attachment.push(k.id)
		});
		let params = {
			type: "contactcard",
			groupid: activeId,
			note: noteId,
			text: note,
			attachments: (attachment.length > 0 ? attachment.join(',') : "")
		}
		onAddNote(params, activeId);
		handleNoteForm(false);
	}

	let notesList = [];

	if (notes !== undefined && notes.length != 0) {
		notesList = notes.map((note, i) => {
			const {id, note:content} = note
			return (
				<CardNotes
					key={i}
					attachments={note.attachments}
					avatar={note.avatar}
					id={id}
					note={content}
					username={note.username}
					writtenDate={note.writtenDate}
					onEdit={()=> handleNoteForm(true,content,id)}
					onDelete={()=> handleDelete(id)}
					disableEditNote={disableEditNote}
				/>
			)
		})
	}

	let saveButtonLabel = noteId ? I("Save") : I("Add note")

	return (
		<div id="contactCardNotes" className="notes-section">
			<div className="notes-addition-section clearfix">
				{showForm ?
					<div className="notes-form">
						<TextInputRow
							id={"text"}
							name="text"
							className="admin-text-input cc-notes"
							overrideClassName="select-row"
							placeholder={"Write here"}
							value={note}
							onChange={(e) => { setNotes(e.target.value) }}
							textArea={true}
							rows={5}
						/>
						<div className="pull-left uploaded-note-attachment">
							<SerialTagList value={noteAttachment}
								showDelete={true}
								onDelete={onAttachmentDelete} />
						</div>
						<div className='pull-right upload'>
							<FileUploader
								cssClass="btn btn-file btn-fileupload"
								title={I("Upload file")}
								uploadTo="errand/notes/uploadAttachment"
								attachSingle={true}
								multipleFile={false}
								uploadAttachment={handleAttachments}
							/>
						</div>
						<div className='action-wrapper'>
							<button type="button" onClick={()=> handleNoteForm(false)} className="btn-grey" title="Cancel" data-qa-id="btn-cancel">{I("Cancel")}</button>
							<button type="button" onClick={handleSave} disabled={false} className="btn-yellow save" title="Save" data-qa-id="btn-Save">{saveButtonLabel}</button>
						</div>
					</div>
					:
					<div className='notes-action-wrapper'>
						{notesList.length === 0 && <p hidden={showForm} className="no-notes">{I("No notes available")}</p>}
						<button type="button" onClick={()=> handleNoteForm(true)} className="btn-blue btn-round add-btn float-right" title="add note" data-qa-id="btn-add-note">
							<i className='icon-add'></i>
						</button>
					</div>
				}
			</div>
			<div className="notes-list-section">
				{notesList}
			</div>
		</div>
	)
}

export default ContactCardsNotes;